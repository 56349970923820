import React from "react";

// mui-material
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";

//-----------------------|| SIDEBAR MENU LIST ||-----------------------//

const MenuList = ({ menus }) => {
  const navItems = menus.items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography
            key={item.id}
            variant="h6"
            align="center"
            sx={{
              color: "error",
            }}
          >
            Menu Items Error
          </Typography>
        );
    }
  });

  return navItems;
};

export default MenuList;
