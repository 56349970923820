import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchGet, fetchPost } from "utils/fetch-webapi";

export const getFranchiseePostTypeLookup = createAsyncThunk(
  "franchiseePostTypeLookup/fetch",
  async () => {
    const [, response] = await fetchGet("/franchisee-post-type-lookup");
    return response;
  }
);

export const postsGet = createAsyncThunk(
  "franchiseePosts/fetch",
  async (args) => {
    const { postType, searchTerm, pageNo, rowsPerPage = 10 } = args;
    const queryStr = `?postTypeId=${postType}&searchTerm=${searchTerm}&pageNo=${pageNo}&rowsPerPage=${rowsPerPage}`;
    const [, response] = await fetchGet("/franchisee-posts" + queryStr);
    return response;
  }
);

export const getFranchiseePostById = createAsyncThunk(
  "franchiseePostsById",
  async (args) => {
    const { id, setAcknowledged } = args;
    const [, response] = await fetchGet(`/franchisee-posts/${id}`, {
      id,
    });

    setAcknowledged(response && response.isAcknowledged);
    return response;
  }
);

export const updateFranchiseePostAcknowledge = createAsyncThunk(
  "franchiseePosts/update",
  async (args) => {
    const { id } = args;
    const [, response] = await fetchPost("/acknowledge-post", {
      franchiseePostId: id,
    });

    return response;
  }
);

const initialState = {
  loading: true,
  postNotFound: false,
  franchiseePosts: [],
  postTypeLookup: null,
  post: null,
  totalCount: 0,
  error: null,
};

const franchiseePostsSlice = createSlice({
  name: "franchiseePosts",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getFranchiseePostTypeLookup.fulfilled, (state, action) => {
        state.postTypeLookup = action.payload;
      })
      .addCase(getFranchiseePostTypeLookup.rejected, (state, action) => {
        state.error = action.error ?? null;
      })
      .addCase(postsGet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postsGet.fulfilled, (state, action) => {
        state.loading = false;
        // Add any fetched posts to the array
        if (action.payload) {
          state.franchiseePosts = action.payload.franchiseePosts;
          state.totalCount = action.payload.totalCount;
        }
      })
      .addCase(postsGet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error ?? null;
      })
      .addCase(getFranchiseePostById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFranchiseePostById.fulfilled, (state, action) => {
        state.loading = false;
        state.post = action.payload;
        state.postNotFound =
          action.payload === null ||
          action.payload === "" ||
          action.payload.id === 0;
      })
      .addCase(getFranchiseePostById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error ?? null;
      })
      // eslint-disable-next-line no-unused-vars
      .addDefaultCase((state, action) => {});
  },
});

export default franchiseePostsSlice.reducer;

export const selectAllFranchiseePosts = (state) => state.franchiseePosts;
