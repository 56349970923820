import React from "react";

// mui-material
import {
  AppBar,
  Box,
  ButtonBase,
  ClickAwayListener,
  Grid2,
  Paper,
  Popper,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// mui-icons
import { MoreVert as IconDotsVertical } from "@mui/icons-material";

// project imports
import LocalizationSection from "../LocalizationSection";
import Transitions from "ui-component/extended/Transitions";

// constants
const PREFIX = "MobileSection";

const classes = {
  grow: `${PREFIX}-grow`,
  paperContainer: `${PREFIX}-paperContainer`,
  popperContainer: `${PREFIX}-popperContainer`,
  menuIcon: `${PREFIX}-menuIcon`,
  toolbar: `${PREFIX}-toolbar`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.grow}`]: {
    //flexGrow: 1
  },

  [`& .${classes.paperContainer}`]: {
    [theme.breakpoints.down("md")]: {
      background: "#fff",

      ...theme.applyStyles("dark", {
        background: theme.palette.dark[800],
      }),
    },
  },

  [`& .${classes.popperContainer}`]: {
    width: "100%",
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      top: "20px !important",
    },
  },

  [`& .${classes.menuIcon}`]: {
    fontSize: "1.5rem",
    marginLeft: "4px",
    cursor: "pointer",
  },

  [`& .${classes.toolbar}`]: {
    paddingTop: "22px",
    paddingBottom: "22px",
  },
}));

//-----------------------|| MOBILE HEADER ||-----------------------//

const MobileSection = () => {
  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down("mobile"));

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Root>
      <Box
        component="span"
        ref={anchorRef}
        sx={{
          mt: 1,
          ml: 1,
        }}
      >
        <ButtonBase
          centerRipple
          sx={(theme) => ({
            color: "inherit",

            ...theme.applyStyles("dark", {
              color: "primary.main",
            }),
          })}
        >
          <IconDotsVertical
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={classes.menuIcon}
          />
        </ButtonBase>
      </Box>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popperContainer}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="zoom"
            in={open}
            {...TransitionProps}
            sx={{ transformOrigin: "top right" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <AppBar className={classes.paperContainer}>
                  <Toolbar className={classes.toolbar}>
                    <Grid2
                      container
                      sx={{
                        justifyContent: matchMobile
                          ? "space-between"
                          : "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <LocalizationSection />
                    </Grid2>
                  </Toolbar>
                </AppBar>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Root>
  );
};

export default MobileSection;
