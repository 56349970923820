import React from "react";

// mui-material
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Grid2,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// mui-icons
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";

// state management
import { useDispatch, useSelector } from "react-redux";
import { LOCALE } from "store/customizationActions";

// third-party
import { useTranslation } from "react-i18next";

// project imports
import { languageList } from "utils/data-lists";
import Transitions from "ui-component/extended/Transitions";

// constants
const PREFIX = "LocalizationSection";

const classes = {
  navContainer: `${PREFIX}-navContainer`,
  headerAvatar: `${PREFIX}-headerAvatar`,
  box: `${PREFIX}-box`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.navContainer}`]: {
    width: "100%",
    minWidth: "200px",
    maxWidth: "280px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "250px",
    },
  },

  [`& .${classes.headerAvatar}`]: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    border: "1px solid",

    borderColor: theme.palette.primary.light,

    background: theme.palette.primary.light,

    color: theme.palette.primary.dark,
    transition: "all .2s ease-in-out",

    '&[aria-controls="menu-list-grow"],&:hover': {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main,
      color: theme.palette.primary.light,
    },

    ...theme.applyStyles("dark", {
      borderColor: theme.palette.dark.main,
      background: theme.palette.dark.main,
    }),
  },

  [`& .${classes.box}`]: {
    marginLeft: "16px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "8px",
    },
  },
}));

//-----------------------|| LOCALIZATION ||-----------------------//

const LocalizationSection = () => {
  const customization = useSelector((state) => state.customization);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [locale, setLocale] = React.useState(customization.locale);

  const handleListItemClick = (value) => {
    i18n.changeLanguage(value);
    setLocale(value);
    dispatch({ type: LOCALE, locale: value });
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    setLocale(customization.locale);
  }, [customization]);

  return (
    <Root>
      <Box component="span" className={classes.box}>
        <Button
          sx={{ borderRadius: "12px" }}
          variant="contained"
          endIcon={<LanguageOutlinedIcon />}
        >
          <Avatar
            variant="rounded"
            className={classes.headerAvatar}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="inherit"
          >
            <Typography
              variant="h5"
              sx={{ textTransform: "uppercase" }}
              //color="inherit"
            >
              {locale}
            </Typography>
          </Avatar>
        </Button>
      </Box>
      <Popper
        placement={matchesXs ? "bottom-start" : "bottom"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? 0 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper elevation={16}>
              <ClickAwayListener onClickAway={handleClose}>
                <List component="nav" className={classes.navContainer}>
                  {languageList.map((lng) => (
                    <ListItemButton
                      key={lng.key}
                      selected={language === lng.key}
                      onClick={() => handleListItemClick(lng.key)}
                    >
                      <ListItemText
                        primary={
                          <Grid2 container>
                            <Typography
                              sx={{
                                color: "textPrimary",
                              }}
                            >
                              {lng.label}
                            </Typography>
                          </Grid2>
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Root>
  );
};

export default LocalizationSection;
