import React from "react";
import { useNavigate } from "react-router-dom";

// mui-material
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid2,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// mui-icons
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconLogout from "@mui/icons-material/Logout";
import IconSettings from "@mui/icons-material/SettingsOutlined";

// state management
import { useSelector, useDispatch } from "react-redux";
import { clearAuthToken, getAuthToken } from "utils/fetch-webapi";
import { responseCurrentFranchiseeAdmin } from "store/authenticationActionReducer";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import { MENU_OPEN, getUserPreferencesMenuOpen } from "store/navigationActions";
import { routePaths } from "routes/Paths";

// constants
const PREFIX = "ProfileSection";

const classes = {
  small: `${PREFIX}-small`,
  medium: `${PREFIX}-medium`,
  large: `${PREFIX}-large`,
  navContainer: `${PREFIX}-navContainer`,
  headerAvatar: `${PREFIX}-headerAvatar`,
  profileLabel: `${PREFIX}-profileLabel`,
  listItem: `${PREFIX}-listItem`,
  cardContent: `${PREFIX}-cardContent`,
  card: `${PREFIX}-card`,
  startAdornment: `${PREFIX}-startAdornment`,
  flex: `${PREFIX}-flex`,
  name: `${PREFIX}-name`,
  ScrollHeight: `${PREFIX}-ScrollHeight`,
  badgeWarning: `${PREFIX}-badgeWarning`,
  box: `${PREFIX}-box`,
  logoutTextColor: `${PREFIX}-logoutTextColor`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.small}`]: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },

  [`& .${classes.medium}`]: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },

  [`& .${classes.large}`]: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },

  [`& .${classes.navContainer}`]: {
    width: "100%",
    maxWidth: "350px",
    minWidth: "300px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
    },
  },

  [`& .${classes.headerAvatar}`]: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: "all .2s ease-in-out",
    width: theme.spacing(7),
    height: theme.spacing(7),

    background: "white",

    color: theme.palette.grey[800],

    '&[aria-controls="menu-list-grow"],&:hover': {
      background: theme.palette.grey[300],

      color: theme.palette.grey[800],

      ...theme.applyStyles("dark", {
        background: theme.palette.warning.dark,
        color: theme.palette.grey[800],
      }),
    },

    ...theme.applyStyles("dark", {
      background: theme.palette.dark.main,
      color: theme.palette.warning.dark,
    }),
  },

  [`& .${classes.profileLabel}`]: {
    lineHeight: 0,
    padding: "12px",
  },

  [`& .${classes.listItem}`]: {
    marginTop: "5px",
  },

  [`& .${classes.cardContent}`]: {
    padding: "16px !important",
  },

  [`& .${classes.card}`]: {
    backgroundColor: theme.palette.primary.light,

    marginBottom: "16px",
    marginTop: "16px",

    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.dark[800],
    }),
  },

  [`& .${classes.startAdornment}`]: {
    fontSize: "1rem",
    color: theme.palette.grey[500],
  },

  [`& .${classes.flex}`]: {
    display: "flex",
  },

  [`& .${classes.name}`]: {
    marginLeft: "2px",
    fontWeight: 400,
  },

  [`& .${classes.ScrollHeight}`]: {
    height: "100%",
    maxHeight: "calc(100vh - 250px)",
    overflowX: "hidden",
  },

  [`& .${classes.badgeWarning}`]: {
    backgroundColor: theme.palette.warning.dark,

    color: "#fff",

    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.dark.dark,
    }),
  },

  [`& .${classes.box}`]: {
    marginLeft: "16px",
    marginRight: "24px",
    [theme.breakpoints.down("md")]: {
      marginRight: "16px",
    },
  },

  [`& .${classes.logoutTextColor}`]: {
    color: "#FF0000",
  },
}));

//-----------------------|| PROFILE MENU ||-----------------------//

const ProfileSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customization = useSelector((state) => state.customization);
  const { currentAdmin } = useSelector((state) => state.authentication);

  const [selectedIndex] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleLogout = async () => {
    try {
      clearAuthToken();
      localStorage.removeItem("persist:root");
      dispatch({
        type: responseCurrentFranchiseeAdmin,
        payload: null,
      });
      if (!getAuthToken()) {
        navigate("/login", { replace: true });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handlePreferences = async () => {
    dispatch({
      type: MENU_OPEN,
      id: getUserPreferencesMenuOpen(),
    });
    navigate(routePaths.UserPreferences);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const greetingsText = () => {
    let myDate = new Date();
    let hrs = myDate.getHours();
    if (hrs < 12) {
      return "Good Morning";
    } else if (hrs >= 12 && hrs <= 17) return "Good Afternoon";
    else if (hrs >= 17 && hrs <= 24) return "Good Evening";
  };

  return (
    <Root>
      <div>
        <Box component="span" className={classes.box}>
          <ButtonBase
            sx={{ borderRadius: "12px" }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <Grid2 container direction="row">
              <Grid2 size={8}>
                <AccountCircleIcon className={classes.medium} />
              </Grid2>
              <Grid2 size={4}>
                <ExpandMoreIcon className={classes.medium} />
              </Grid2>
            </Grid2>
          </ButtonBase>
        </Box>
      </div>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <CardContent className={classes.cardContent}>
                    <Grid2 container direction="column" spacing={0}>
                      <Grid2 className={classes.flex}>
                        <Typography variant="h4">{greetingsText()},</Typography>
                        <Typography
                          component="span"
                          variant="h4"
                          className={classes.name}
                        >
                          {currentAdmin && currentAdmin.franchisee.firstName}
                        </Typography>
                      </Grid2>
                    </Grid2>
                    <List component="nav" className={classes.navContainer}>
                      <ListItemButton
                        className={classes.listItem}
                        sx={{
                          borderRadius: customization.borderRadius + "px",
                        }}
                        selected={selectedIndex === 0}
                        onClick={handlePreferences}
                      >
                        <ListItemIcon>
                          <IconSettings />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body2">Preferences</Typography>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton
                        className={classes.listItem}
                        sx={{
                          borderRadius: customization.borderRadius + "px",
                        }}
                        selected={selectedIndex === 0}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <IconLogout className={classes.logoutTextColor} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              className={classes.logoutTextColor}
                              variant="subtitle1"
                              sx={{
                                fontSize: "14px",
                              }}
                            >
                              Logout
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </CardContent>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Root>
  );
};

export default ProfileSection;
